import React, {useState, useEffect, useRef} from 'react'
import { useParams } from 'react-router-dom'
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Box from "@mui/material/Box";
import CloseIcon from "@mui/icons-material/Close";
import Alert from "@mui/material/Alert";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from 'react-router-dom';
import { url } from '../../../../Config';
import { Form } from 'react-router-dom';


export default function RcCoverApproval(props) {
    const Navigate = useNavigate();
    const targetRef = useRef(null);
    const { client_id, og_rating_id } = useParams();
    const id = localStorage.getItem("userID");
    const [Rating_data, setRating_data] = useState(null)
    const [history, sethistory] = useState([]);
    const [sector_study, setsector_study] = useState([]);
    const [methodology, setmethodology] = useState([]);
    const [rc_members_internal, setRc_members_internal] = useState([]);
    const [rc_members_external, setRc_members_external] = useState([]);
    const [rc_guest_members, setRc_guest] = useState([]);
    const [approvalIds, setapprovalIds] = useState([]);
    const [data, setData] = useState(null);
    const [alertopen, setalertopen] = useState(false);
    const [AlertMessage, setAlertMessage] = useState(null);
    const [alertType, setalertType] = useState("error");
    const [alertopen2, setalertopen2] = useState(false);
    const [AlertMessage2, setAlertMessage2] = useState(null);
    const [alertType2, setalertType2] = useState("error");
    const [state, setstate] = useState(true);
    const [AutoIC, setAutoIC] = useState(true);




    function DateFormat(params) {
        if (params == null) {
          return "-";
        } else {
          const date = new Date(params);
          const yyyy = date.getFullYear();
          const yy = yyyy.toString();
          const y = yy.slice(2, 4);
          let mm = date.toLocaleString("default", { month: "short" });
          let dd = date.getDate();
          if (dd < 10) dd = "0" + dd;
          return dd + "-" + mm + "-" + y;
        }
      }

      const Datefunc = (date) => {
        const dateString = date;
        const dateObj = new Date(dateString);
        const month = new Intl.DateTimeFormat("en-US", { month: "short" }).format(
          dateObj
        );
        const year = dateObj.getFullYear().toString().slice(-2);
        const formattedDate = `${month}-${year}`;
        return formattedDate;
      };


    useEffect(()=>{
        setRating_data(null);
        const func = async ()=>{
            const res = await axios.get(`${url}/api/RC/onlyapprove/${id}/${client_id}/${og_rating_id}`);
            const resp = res.data;
            if(res.data.message == "RC not done"){
                Navigate(`/Ratings/RC_Cover/Coverpageindex/${client_id}/${og_rating_id}`);
            }
            else{
                setData(resp);
                setRating_data(resp.data[0]);
                sethistory(resp.history);
                setsector_study(resp.sector_study);
                setmethodology(resp.methodology);
                setRc_members_internal(resp.rc_members_internal);
                setRc_members_external(resp.rc_members_external);
                setRc_guest(resp.rc_guest_members);
                const ids = resp.data[0].approvalIds;
                if(ids !== null){
                    const idsarr = ids.split(",");
                    setapprovalIds(idsarr);
                }
            }

        }
        func();

    },[state])

    const handleSwitchChange = () => {
      setAutoIC(!AutoIC);
    };

    const approval = async(docid, docid2)=>{
      try {
          
          document.getElementById(docid2).disabled = true;
          document.getElementById(docid2).innerHTML = "Please wait <i class='fa-solid fa-spinner fa-spin fa-lg'></i>"
          const approval_res = await axios.post(`${url}/api/approve/rc_data`,{
              "userid": Number(id),
              "record_id" : Number(og_rating_id),
              "opinion_id": Number(client_id),
              "user_id": Number(id),
              "autoIC" : AutoIC
          })
          const approval_resp = approval_res.data;
          if(approval_resp.message == "RC Date is older than x days"){
              targetRef.current.scrollIntoView({ behavior: 'smooth' });
              setalertType('error');
              setAlertMessage(`RC Date is older than ${approval_resp.days} days`);
              setalertopen(true);
              document.getElementById(docid2).disabled = false;
              document.getElementById(docid2).innerHTML = "Approve"
          }
          
          if(approval_resp.rating_status == true && approval_resp.status == false && approval_resp.message !== "Approval Declined! Analyst/Team Lead has not added reason for delay yet"){
              document.getElementById(docid).innerHTML = "RC is Approved. Signatures will appear once pdf is generated. Please wait..."
              axios({
                  url: `${url}/api/pdfcover/${client_id}/${og_rating_id}/${Rating_data.rating_scale_id}/${null}`,
                  method: 'Get',
                  responseType: 'blob',
                }).then(response => {
                  document.getElementById(docid).innerHTML = `<img src='${'data:image/jpg;base64,'+approval_resp.sign}' style='width:120px; height:60px' />`;
                  const file = new Blob([response.data], { type: 'application/pdf' })
                  const fileURL = URL.createObjectURL(file)
                  window.open(fileURL);

                }).catch(
                  error => {
                    document.getElementById(docid).innerHTML = `<img src='${'data:image/jpg;base64,'+approval_resp.sign}' style='width:120px; height:60px' />`;
                    console.error(error)
                  }
                  )
          
          }
          else if(approval_resp.rating_status == true && approval_resp.status == true){
            if(id == "19"){
              document.getElementById(docid).innerHTML = "RC and IC is Approved. Signatures will appear once pdf is generated. Please wait..."
              axios({
                url: `${url}/api/pdfcover/${client_id}/${og_rating_id}/${Rating_data.rating_scale_id}/${null}`,
                method: 'Get',
                responseType: 'blob',
              }).then(response => {
                document.getElementById(docid).innerHTML = `<img src='${'data:image/jpg;base64,'+approval_resp.sign}' style='width:120px; height:60px' />`;
                const file = new Blob([response.data], { type: 'application/pdf' })
                const fileURL = URL.createObjectURL(file)
                window.open(fileURL);

              }).catch(
                error => {
                  document.getElementById(docid).innerHTML = `<img src='${'data:image/jpg;base64,'+approval_resp.sign}' style='width:120px; height:60px' />`;
                  console.error(error)
                }
                )

                window.open(`${url}/api/printpreview/${client_id}/${og_rating_id}`);
                document.getElementById("autoic").hidden = true;
            }
              
          }

          else if(approval_resp.rating_status == true && approval_resp.status == false && approval_resp.message == "Approval Declined! Analyst/Team Lead has not added reason for delay yet"){
              document.getElementById(docid2).disabled = false;
              document.getElementById(docid2).innerHTML = "Approve"
              setalertType('error');
              setAlertMessage(approval_resp.message);
              setalertopen(true);
          }
          else if(approval_resp.rating_status == false){
              document.getElementById(docid2).disabled = false;
              document.getElementById(docid2).innerHTML = "Approve"
              setalertType('error');
              setAlertMessage('Some error occure while approving RC');
              setalertopen(true);
          }
      } catch (error) {
        setalertType('error');
        setAlertMessage('Some Error occure while Approving RC. Reload The page if Approve Button is not appearing');
        setalertopen(true);
        document.getElementById(docid2).disabled = false;
        document.getElementById(docid2).innerHTML = "Approve"
      }
  }

    const PDF = async()=>{
      // document.getElementById("pdf").hidden = true;
      // document.getElementById("pdfwait").hidden = false;
      window.open(`${url}/api/pdfcover/${client_id}/${og_rating_id}/${Number(Rating_data.rating_scale_id)}/1`, '_blank');
      // axios({
      //   url: `${url}/api/pdfcover/${client_id}/${og_rating_id}/${Number(Rating_data.rating_scale_id)}/1`,
      //   method: 'Get',
      //   responseType: 'blob',
      // }).then(response => {
      //   console.log(response, "response");
      //   const file = new Blob([response.data], { type: 'application/pdf' })
      //   const fileURL = URL.createObjectURL(file)
      //   window.open(fileURL)
      //   document.getElementById("pdf").hidden = false;
      //   document.getElementById("pdfwait").hidden = true;

      // }).catch(
      //   error => {
      //     console.error(error)
      //     setalertType('error');
      //     setAlertMessage('Some Error occure while loading PDF');
      //     setalertopen(true);
      //     document.getElementById("pdf").hidden = false;
      //     document.getElementById("pdfwait").hidden = true;
      //   }
      //   )
    }
    const UpdatePDF = async()=>{
      window.open(`${url}/api/pdfcover/${client_id}/${og_rating_id}/${Number(Rating_data.rating_scale_id)}/${null}`, '_blank');
      // document.getElementById("pdf1").hidden = true;
      // document.getElementById("pdfwaits").hidden = false;
      // axios({
      //   url: `${url}/api/pdfcover/${client_id}/${og_rating_id}/${Number(Rating_data.rating_scale_id)}/${null}`,
      //   method: 'Get',
      //   responseType: 'blob',
      // }).then(response => {
      //   console.log(response, "response");
      //   const file = new Blob([response.data], { type: 'application/pdf' })
      //   const fileURL = URL.createObjectURL(file)
      //   window.open(fileURL)
      //   document.getElementById("pdf1").hidden = false;
      //   document.getElementById("pdfwaits").hidden = true;

      // }).catch(
      //   error => {
      //     console.error(error)
      //     setalertType('error');
      //     setAlertMessage('Some Error occure while loading PDF');
      //     setalertopen(true);
      //     document.getElementById("pdf1").hidden = false;
      //     document.getElementById("pdfwaits").hidden = true;
      //   }
      //   )
    }

    const AppealRc = async(appeal)=>{


        const rc_external_arr = Rating_data.rc_external.split(',');
        const rc_internal_arr = Rating_data.rc_internal.split(',');
        let approvalIdsarr = [];
        if(Rating_data.approvalIds !== null && Rating_data.approvalIds !== ""){
            approvalIdsarr  = Rating_data.approvalIds.split(',');
        }
         

        for(let i in rc_external_arr){
            if(approvalIdsarr.includes(rc_external_arr[i])){
            }
            else{
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
                setalertType('error');
                setAlertMessage("Can't generate review/appeal, due to pending approvals");
                setalertopen(true);
                return;
            }
        }

        for(let i in rc_internal_arr){
            if(approvalIdsarr.includes(rc_internal_arr[i])){

            }
            else{
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
                setalertType('error');
                setAlertMessage("Can't generate review/appeal, due to pending approvals");
                setalertopen(true);
                return;
            }
        }

        if(approvalIdsarr.includes(`${Rating_data.analystId}`)){

        }
        else{   
                console.log(Rating_data.analystId);
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
                setalertType('error');
                setAlertMessage("Can't generate review/appeal, due to pending approvals");
                setalertopen(true);
                return;
            }

        if(approvalIdsarr.includes(`${Rating_data.supervisor_id}`)){

        }
        else{   
                console.log(Rating_data.supervisor_id);
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
                setalertType('error');
                setAlertMessage("Can't generate review/appeal, due to pending approvals");
                setalertopen(true);
                return;
            }
            if(appeal == "appeal rc"){
              document.getElementById("appealbtn").innerHTML = "Please Wait...";
            }
            else if(appeal == "review rc"){
              document.getElementById("reviewbtn").innerHTML = "Please Wait...";
            }

            axios({
              url: `${url}/api/pdfcover/${client_id}/${og_rating_id}/${Rating_data.rating_scale_id}/1`,
              method: 'Get',
              responseType: 'blob',
            }).then(response => {
              const func = async ()=>{
                const res = await axios.post(`${url}/api/appeal`, {
                  "record_id": Number(og_rating_id),
                  "opinion_id": Number(client_id),
                  "updated_by": Number(id),
                  "appeal": appeal
              })
              const resp = res.data;
              if(resp.message == "Appeal RC Sucessfully Generated"){
                  setstate(!state);
                  setalertType('success');
                  setAlertMessage("Appeal/Review RC Sucessfully Generated");
                  setalertopen(true);
                  if(appeal == "appeal rc"){
                    document.getElementById("appealbtn").innerHTML = "Appeal RC";
                  }
                  else if(appeal == "review rc"){
                    document.getElementById("reviewbtn").innerHTML = "Review RC";
                  }
                  
              }
              else if(resp.message == "Generate PPL First"){
                targetRef.current.scrollIntoView({ behavior: 'smooth' });
                  setalertType('error');
                  setAlertMessage("Generate PPL First!");
                  setalertopen(true);
                  if(appeal == "appeal rc"){
                    document.getElementById("appealbtn").innerHTML = "Appeal RC";
                  }
                  else if(appeal == "review rc"){
                    document.getElementById("reviewbtn").innerHTML = "Review RC";
                  }
              }
              else{
                  targetRef.current.scrollIntoView({ behavior: 'smooth' });
                  setalertType('error');
                  setAlertMessage("Some error occure while proceeding your request");
                  setalertopen(true);
                  if(appeal == "appeal rc"){
                    document.getElementById("appealbtn").innerHTML = "Appeal RC";
                  }
                  else if(appeal == "review rc"){
                    document.getElementById("reviewbtn").innerHTML = "Review RC";
                  }
              }
            }
            func();
              
      
            }).catch(
              error => {
                console.error(error)
                setalertType('error');
                setAlertMessage('Some Error occure while loading PDF');
                setalertopen(true);
                document.getElementById("pdf").hidden = false;
                document.getElementById("pdfwait").hidden = true;
              }
              )
    }

    const addreasonforDelay = async()=>{
      const res = await axios.post(`${url}/api/reasonfordelay`,{
        'opinion_id': client_id, 
        'record_id' : og_rating_id,
        'reason' : document.getElementById('reason').value
      });
      const resp = res.data;
      if(resp.status == true){
        setalertType2('success');
        setAlertMessage2('Comment added!');
        setalertopen2(true);
      }
      else{
        setalertType2('error');
        setAlertMessage2('Some Error occure while Processing your request');
        setalertopen2(true);
      }
    }

  return (
    <div>
      <div
        className="fs-3 fw-bold text-center mt-2"
        style={{ color: "#000078", fontFamily: "Kalinga" }}
      >
        Rating Committee Approval
      </div>
      <br />
    {data !== null ? 
    <div className='card shadow col-11 mx-auto p-2 border border-dark' style={{backgroundColor: "whitesmoke"}}>
        <div className='card shadow p-3 border border-dark'>
            <div className='row'>
                <div className='col-lg-6 col-md-6 col-12 row'>
                    <div className='col-lg-2 col-md-2 col-12 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        Opinion:
                    </div>
                    <div className='col-lg-8 col-md-8 col-10'>
                        <input className='form-control' disabled value={Rating_data && Rating_data.company_name}></input>
                    </div>
                </div>
                <div className='col-lg-6 col-md-6 col-12 row'>
                    <div className='col-lg-2 col-md-2 col-12 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        RC Date:
                    </div>
                    <div className='col-lg-8 col-md-8 col-10'>
                        <input className='form-control' disabled value={DateFormat(Rating_data && Rating_data.date)}></input>
                    </div>
                </div>
            </div>
        </div>
        <br />
        <div ref={targetRef} className='card shadow p-3 border border-dark' style={{backgroundColor: "whitesmoke"}}>
            <table className='table newtable'>
                <tbody>
                <tr>
                <td className='col-3 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                    Action
                </td>
                <td className='col-9'>
                    {Rating_data && Rating_data.opinion_id == 0 && <button className={`btn btn-primary ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`}>Legacy PDF</button>}
                    <button className={`btn btn-primary ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} onClick={()=>{PDF()}}><div id='pdf'>View PDF</div><div id='pdfwait' hidden>Please Wait...</div></button>
                    <button className={`btn btn-danger ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} onClick={()=>{UpdatePDF()}}><div id='pdf1'>Update PDF</div><div id='pdfwaits' hidden>Please Wait...</div></button>
                    {Rating_data && Rating_data.analystId == Number(id) && Rating_data.editable != 0 && <button className={`btn btn-warning ${props.screenWidth <= 576 ? 'btn-sm' : null} ms-1 mb-1 me-1`} onClick={()=>{window.open(`/Ratings/RC_Cover/Edit/${client_id}/${og_rating_id}`)}}>Edit</button> || Rating_data && Rating_data.supervisor_id == Number(id) && Rating_data.editable != 0 && <button className={`btn btn-warning ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} onClick={()=>{window.open(`/Ratings/RC_Cover/Edit/${client_id}/${og_rating_id}`, '_self')}}>Edit</button>}
                    {Rating_data && Rating_data.analystId != Number(id) && Rating_data.supervisor_id != Number(id) && Rating_data.notification_date == null && <button className={`btn btn-warning ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} onClick={()=>{window.open(`/Ratings/RC_Cover/Edit/${client_id}/${og_rating_id}`, '_self')}}>Edit</button>}
                    {Rating_data && data ?
                    Number(Rating_data.rating_update_type) == 6 || Number(Rating_data.rating_update_type) == 4 || Number(Rating_data.rating_update_type) == 7 || Number(Rating_data.rating_update_type) == 1 ?
                    Number(Rating_data.final_long_term) == Number(data.secondLastRecord.pacra_lterm) &&
                    Number(Rating_data.final_short_term) == Number(data.secondLastRecord.pacra_sterm) &&
                    Number(Rating_data.final_outlook) == Number(data.secondLastRecord.pacra_outlook) ?
                    Number(id) !== 19 ?
                    data.daystoreviewDeadline !== null && data.daystoreviewDeadline < 0 ?
                    Rating_data.notification_date == null ?
                    <button className={`btn btn-info ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} data-bs-toggle="modal" data-bs-target="#exampleModal">Reason For Delay</button>
                    : null : null : null : null
                    : null : null
                    }

                <div
                  className="modal fade"
                  id="exampleModal"
                  tabIndex="-1"
                  aria-labelledby="commentfield4"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">
                          Reason For Delay
                        </h5>
                        <button
                          type="button"
                          className="btn-close"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                        ></button>
                      </div>
                      <div className="modal-body">
                        <div id="message"></div>
                        <>
                        <Box className="mx-auto" sx={{ width: "98%" }}>
                                    <Collapse in={alertopen2}>
                                      <Alert
                                        severity={alertType2}
                                        action={
                                          <IconButton
                                            aria-label="close"
                                            color="inherit"
                                            size="small"
                                            onClick={() => {
                                              setalertopen(false);
                                            }}
                                          >
                                            <CloseIcon fontSize="inherit" />
                                          </IconButton>
                                        }
                                        sx={{ mb: 2 }}
                                      >
                                        {AlertMessage2}
                                      </Alert>
                                    </Collapse>
                        </Box>
                        </>
                        <textarea
                          className="form-control"
                          maxLength="150"
                          defaultValue={data.review}
                          minLength="10"
                          id="reason"
                        />
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          id="btnclose"
                          className="btn btn-secondary"
                          data-bs-dismiss="modal"
                        >
                          Close
                        </button>
                        <button
                          type="button"
                          className="btn btn-primary"
                          onClick={() => {
                            addreasonforDelay();
                            // clickupdate();
                            // document.getElementById("btnclose4").click();
                          }}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                    
                    {Rating_data ? Rating_data.analystId != Number(id) && Rating_data.supervisor_id != Number(id) && Rating_data.notification_date == null && <><button className={`btn btn-secondary ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} id='appealbtn' onClick={()=>{AppealRc("appeal rc")}}>Appeal RC</button>
                    <button className={`btn btn-info ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} id='reviewbtn' onClick={()=>{AppealRc("review rc")}}>Review RC</button></> : null}

                    {Rating_data && data ?
                    Number(Rating_data.rating_update_type) == 6 || Number(Rating_data.rating_update_type) == 4 || Number(Rating_data.rating_update_type) == 7 || Number(Rating_data.rating_update_type) == 1 ?
                    Number(Rating_data.final_long_term) == Number(data.secondLastRecord.pacra_lterm) &&
                    Number(Rating_data.final_short_term) == Number(data.secondLastRecord.pacra_sterm) &&
                    Number(Rating_data.final_outlook) == Number(data.secondLastRecord.pacra_outlook) ?
                    Number(id) == 19 ?
                      
                      <div class="form-check form-switch" id='autoic'>
                      <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" onChange={handleSwitchChange} checked={AutoIC} />
                      <label class="form-check-label" for="flexSwitchCheckChecked">Auto IC</label>
                      </div>
                     : null : null : null : null}
                    {/* {
                    Rating_data && data ?
                    Number(Rating_data.rating_update_type) == 6 || Number(Rating_data.rating_update_type) == 4 ?
                    Number(Rating_data.final_long_term) == Number(data.secondLastRecord.pacra_lterm) &&
                    Number(Rating_data.final_short_term) == Number(data.secondLastRecord.pacra_sterm) &&
                    Number(Rating_data.final_outlook) == Number(data.secondLastRecord.pacra_outlook) ?
                    (Number(id) == Rating_data.supervisor_id || Number(id) == Rating_data.analystId) &&
                    approvalIds.includes(id) ?
                    <>
                    <button className={`btn btn-danger ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} onClick={()=>{window.open(`/Ratings/closer_internally/${client_id}/${og_rating_id}`)}}>IC</button>
                    </>
                    :<button className={`btn btn-danger ${props.screenWidth <= 576 ? 'btn-sm' : null} me-1 mb-1`} id='icbtn' hidden onClick={()=>{window.open(`/Ratings/closer_internally/${client_id}/${og_rating_id}`)}}>IC</button>
                    : null
                    :null
                    : null
                    } */}
                </td>
                </tr>
                </tbody>
            </table>
        </div>
        <br />
        <>
        <Box className="mx-auto" sx={{ width: "98%" }}>
                    <Collapse in={alertopen}>
                      <Alert
                        severity={alertType}
                        action={
                          <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={() => {
                              setalertopen(false);
                            }}
                          >
                            <CloseIcon fontSize="inherit" />
                          </IconButton>
                        }
                        sx={{ mb: 2 }}
                      >
                        {AlertMessage}
                      </Alert>
                    </Collapse>
        </Box>
        </>
        <br />
        {Rating_data != null && <>
          <div className='card shadow p-3 border border-dark' style={{backgroundColor: "whitesmoke"}}>
        <div className="fs-4 text-center" style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Committee Members
          </div>
          <hr />
        <table className='table newtable'>
            <thead>
                <tr>
                    <th className='col-6 fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        Members
                    </th>
                    <th className='col-6 fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        Signatures
                    </th>
                </tr>
            </thead>
            <tbody>
                {
                 rc_members_internal && rc_members_internal.map((value, index)=>{
                    return(
                        <>
                            <tr>
                                <td className='col-6 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                                        {value.name} | Internal Member
                                    </td>
                                    <td className='col-6' style={{fontFamily: "Kalinga" }}>
                                        {value.uid == Number(id) ? approvalIds.includes(`${value.id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <div id={`internal${value.uid}`}> <button className='btn btn-success' id={`btn-internal${value.uid}`} onClick={()=>{approval(`internal${value.uid}`, `btn-internal${value.uid}`)}}> Approve </button>  </div> : approvalIds.includes(`${value.id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <b>Pending...</b>}
                                    </td>
                                </tr>
                        </>
                            )
                        })
                }
                
                {
                   rc_members_external && rc_members_external.map((value, index)=>{
                    return(
                        <>
                            <tr>
                                <td className='col-6 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                                        {value.name} | {Rating_data.rc_chairman == value.id ? <>RC Chairperson</> : <>External Member</>}
                                    </td>
                                    <td className='col-6' style={{fontFamily: "Kalinga" }}>
                                        {value.uid == Number(id) ? approvalIds.includes(`${value.id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <div id={`external${value.uid}`}><button className='btn btn-success' id={`btn-external${value.uid}`} onClick={()=>{approval(`external${value.uid}`, `btn-external${value.uid}`)}}> Approve </button> </div> : approvalIds.includes(`${value.id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <b>Pending...</b>}
                                    </td>
                            </tr>
                        </>
                            )
                        })
                }
                {
                   rc_guest_members && rc_guest_members.map((value, index)=>{
                    return(
                        <>
                            <tr>
                                <td className='col-6 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                                        {value.display_name} | Guest
                                    </td>
                                    <td className='col-6' style={{fontFamily: "Kalinga" }}>
                                        {value.u_id == Number(id) ? approvalIds.includes(`${value.u_id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <div id={`guest${value.u_id}`}><button className='btn btn-success' id={`btn-guest${value.u_id}`} onClick={()=>{approval(`guest${value.u_id}`, `btn-guest${value.u_id}`)}}> Approve </button></div>: approvalIds.includes(`${value.u_id}`) ? <img src={`${'data:image/jpg;base64,'+value.sign}`} style= {{width : '120px', height: '60px'}}></img> : <b>Pending...</b>}
                                    </td>
                            </tr>
                        </>
                            )
                        })
                }
            </tbody>
        </table>
        </div> 
        <br />
        <div className='card shadow p-3 border border-dark' style={{backgroundColor: "whitesmoke"}}>
        <div className="fs-4 text-center" style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Team
          </div>
          <hr />
        <table className='table newtable'>
            <thead>
                <tr>
                    <th className='col-6 fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        Team
                    </th>
                    <th className='col-6 fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                        Signatures
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className='col-6 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                    {Rating_data && Rating_data.supervisor_name} | Team Lead
                    </td>
                    <td className='col-6' style={{fontFamily: "Kalinga" }}>
                    {Rating_data && Number(id) == Rating_data.supervisor_id ? approvalIds.includes(`${Rating_data.supervisor_id}`) ? <img src={`${'data:image/jpg;base64,'+Rating_data.manager_sign}`} style= {{width : '120px', height: '60px'}}></img> : <div id='manager'> <button className='btn btn-primary' id='btn-manager' onClick={()=>{approval('manager', 'btn-manager')}}> Approve </button> </div> : Rating_data && approvalIds.includes(`${Rating_data.supervisor_id}`) ? <img src={`${'data:image/jpg;base64,'+Rating_data.manager_sign}`} style= {{width : '120px', height: '60px'}}></img> : <b>Pending...</b>}
                    </td>
                </tr>
                <tr>
                    <td className='col-6 fw-bold fs-6' style={{color: "#000078", fontFamily: "Kalinga" }}>
                    {Rating_data && Rating_data.analyst} | Analyst
                    </td>
                    <td className='col-6' style={{fontFamily: "Kalinga" }}>
                    {Rating_data && Number(id) == Rating_data.analystId ? approvalIds.includes(`${Rating_data.analystId}`) ? <img src={`${'data:image/jpg;base64,'+Rating_data.analyst_sign}`} style= {{width : '120px', height: '60px'}}></img> : <div id='analyst'> <button className='btn btn-primary' id='btn-analyst' onClick={()=>{approval('analyst', 'btn-analyst')}}> Approve </button> </div> : Rating_data && approvalIds.includes(`${Rating_data.analystId}`) ? <img src={`${'data:image/jpg;base64,'+Rating_data.analyst_sign}`} style= {{width : '120px', height: '60px'}}></img> : <b>Pending...</b>}
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        </>}
        <br />

        <div className="fs-4 text-center" style={{ color: "#000078", fontFamily: "Kalinga" }}>
        Applicable Criteria & Related Research:
        </div>
        <br />

        <div className='card shadow p-3 border border-dark' style={{backgroundColor: "whitesmoke"}}>
        <table className='table newtable'>
            <tbody>
                <tr>
                    <td className='col-4 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                    Sector Study
                    </td>
                    <td className='col-8' style={{ fontFamily: "Kalinga" }}>
                        {
                            sector_study && sector_study.map((value, index)=>{
                                return(
                                    <div> {`${index+1}- ${value.name} | ${Datefunc(value.date)}`} </div>
                                )
                            })
                        }
                    </td>
                </tr>
                <tr>
                    <td className='col-4 fw-bold fs-6' style={{ color: "#000078", fontFamily: "Kalinga" }}>
                    Methodology
                    </td>
                    <td className='col-8' style={{fontFamily: "Kalinga" }}>
                        {
                            methodology && methodology.map((value, index)=>{
                                return(
                                    <div> {`${index+1}- ${value.name} | ${Datefunc(value.date)}`} </div>
                                )
                            })
                        }
                    </td>
                </tr>
            </tbody>
        </table>
        </div>
        <br />

        <div className='card shadow p-3 border border-dark' style={{ overflow: "auto", background: "whitesmoke" }}>
        <div className="fs-4 text-center" style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating History
          </div>
          <hr />
          <table
            className="table newtable table-secondary"
            style={{ fontFamily: "Kalinga" }}
          >
            <tbody>
              {Rating_data && Rating_data.rating_scale_id && Rating_data.rating_scale_id == 14 ? (
                <>
                  <tr>
                    <td className="col-2 fw-bold">Dissemination Date</td>
                    <td className="col-2 fw-bold">Security Grading</td>
                    <td className="col-2 fw-bold">Action</td>
                  </tr>
                </>
              ) : 
              Rating_data && Rating_data.rating_scale_id && Rating_data.rating_scale_id == 7 ? (
                <>
                 <tr>
                    <td className="col-1 fw-bold">Dissemination Date</td>
                    <td className="col-1 fw-bold">1 year</td>
                    <td className="col-1 fw-bold">3 year</td>
                    <td className="col-1 fw-bold">5 year</td>
                    <td className="col-1 fw-bold">1-Year Action</td>
                    <td className="col-1 fw-bold">3-Year Action</td>
                    <td className="col-1 fw-bold">5-Year Action</td>
                    <td className="col-1 fw-bold">Criticality Factor</td>
                    <td className="col-1 fw-bold">Decision</td>
                  </tr>
                </>) :
              Rating_data && Rating_data.rating_scale_id && (Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9) ?
              (
                <>
                  <tr>
                    <td className="col-2 fw-bold">Dissemination Date</td>
                    <td className="col-2 fw-bold">Long Term Rating</td>
                    <td className="col-2 fw-bold">Short Term Rating</td>
                    <td className="col-1 fw-bold">Outlook</td>
                    <td className="col-1 fw-bold">Action</td>
                    <td className="col-2 fw-bold">Criticality Factor</td>
                    <td className="col-1 fw-bold">Decision</td>
                    <td className="col-1 fw-bold">Rating Watch</td>
                  </tr>
                </>
              ) :
              (
                <>
                  <tr>
                    <td className="col-2 fw-bold">Dissemination Date</td>
                    <td className="col-2 fw-bold">{Rating_data && Rating_data.rating_scale_id && Rating_data.rating_scale_id == 2 ? 'IFS Rating' : 'Rating'}</td>
                    <td className="col-1 fw-bold">Outlook</td>
                    <td className="col-1 fw-bold">Action</td>
                    <td className="col-2 fw-bold">Criticality Factor</td>
                    <td className="col-1 fw-bold">Decision</td>
                    <td className="col-1 fw-bold">Rating Watch</td>
                  </tr>
                </>
              )
            
            }
              {history && history.map((value, index) => {
                if(Rating_data !== null){
                    if (Rating_data.rating_scale_id == 14) {
                      return (
                        <tr>
                          <td className="col-2">
                            {DateFormat(value.Dissemination)}
                          </td>
                          <td className="col-2">{value.RatingLT}</td>
                          <td className="col-2">{value.RatingAction}</td>
                        </tr>
                      );
                    } 

                    else if (Rating_data.rating_scale_id == 7) {
                      return(
                        <tr>
                          <td className="col-1">
                            {DateFormat(value.Dissemination)}
                          </td>
                          <td className="col-1">{value.RatingST}</td>
                          <td className="col-1">{value.RatingLT}</td>
                          <td className="col-1">
                              {value.Rating5yr !== null ? value.Rating5yr : "-"}
                          </td>
                          <td className="col-1">{value.st_action}</td>
                          <td className="col-1">{value.RatingAction}</td>
                          <td className="col-1">{value.yr5_action}</td>
                          <td className="col-1">{value.fcf}</td>
                          <td className="col-1">{value.rcd}</td>
                        </tr>
                      )
                    }

                    else if(Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9){
                      return (
                        <tr>
                          <td className="col-2">
                            {DateFormat(value.Dissemination)}
                          </td>
                          <td className="col-2">{value.RatingLT}</td>
                          <td className="col-2">{value.RatingST}</td>
                          <td className="col-1">{value.Outlook}</td>
                          <td className="col-2">{value.RatingAction}</td>
                          <td className="col-1">{value.fcf}</td>
                          <td className="col-1">{value.rcd}</td>
                          <td className="col-1">{value.rw}</td>
                        </tr>
                      );
                    }
                    else{
                      return (
                        <tr>
                          <td className="col-2">
                            {DateFormat(value.Dissemination)}
                          </td>
                          <td className="col-2">{value.RatingLT}</td>
                          <td className="col-1">{value.Outlook}</td>
                          <td className="col-2">{value.RatingAction}</td>
                          <td className="col-1">{value.fcf}</td>
                          <td className="col-1">{value.rcd}</td>
                          <td className="col-1">{value.rw}</td>
                        </tr>
                      );
                    }
                }
                })}
            </tbody>
          </table>
        </div>
        <br/>

        <div className='card shadow p-3 border border-dark' style={{ overflow: "auto", backgroundColor: "whitesmoke" }}>
        <div className="fs-4 text-center" style={{ color: "#000078", fontFamily: "Kalinga" }}>
            Rating Action
        </div>
          <hr />
        <table className='table newtable table-secondary'>
            {Rating_data && Rating_data.rating_scale_id && Rating_data.rating_scale_id !== 7 ?
            <tbody>
                <tr>
                    <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Type</td>
                    <td className='fw-bold col-2' style={{fontFamily: "Kalinga" }}>{Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9 ? "Long Term Rating" : Rating_data.rating_scale_id == 2 ? "IFS Rating" : "Rating"}</td>
                    {Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9 ? 
                    <td className='fw-bold col-2' style={{fontFamily: "Kalinga" }}>Short Term Rating</td> : null}
                    <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Outlook</td>
                    <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Action</td>
                    <td className='fw-bold col-2' style={{fontFamily: "Kalinga" }}>Rating Watch</td>
                    <td className='fw-bold col-2' style={{fontFamily: "Kalinga" }}>Criticality Factor</td>
                    <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Decision</td>
                </tr>
                <tr>
                    <td className='col-1'>Proposed</td>
                    <td className='col-2'>{data && data.proposed_l_term || '-'}</td>
                    {Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9 ?
                    <td className='col-2'>{data && data.propsed_s_term || '-'}</td> : null}
                    <td className='col-1'>{data && data.proposed_outlook || '-'}</td>
                    <td className='col-1'>{data && data.proposed_rating_action || '-'}</td>
                    <td className='col-2'>{Rating_data && Rating_data.proposed_rating_watch || '-'}</td>
                    <td className='col-2'>{data && data.proposed_cf || '-'}</td>
                    <td className='col-1'></td>
                </tr>
                <tr>
                    <td className='col-1'>Final</td>
                    <td className='col-2'>{data && data.final_l_term || '-'}</td>
                    {Rating_data.rating_scale_id == 1 || Rating_data.rating_scale_id == 9 ?
                    <td className='col-2'>{data && data.final_s_term || '-'}</td> : null}
                    <td className='col-1'>{data && data.final_outlook || '-'}</td>
                    <td className='col-1'>{data && data.final_rating_action || '-'}</td>
                    <td className='col-2'>{Rating_data && Rating_data.final_rating_watch || '-'}</td>
                    <td className='col-2'>{data && data.final_cf || '-'}</td>
                    <td className='col-1'>{Rating_data && Rating_data.rc_decision || '-'}</td>
                </tr>
            </tbody> :
            <tbody>
            <tr>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Type</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>1 Year</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>3 Year</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>5 Year</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>1-Year Action</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>3-Year Action</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>5-Year Action</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Criticality Factor</td>
                <td className='fw-bold col-1' style={{fontFamily: "Kalinga" }}>Decision</td>
            </tr>
            <tr>
                <td className='col-1'>Proposed</td>
                <td className='col-1'>{data && data.propsed_s_term || '-'}</td>
                <td className='col-1'>{data && data.proposed_l_term || '-'}</td>
                <td className='col-1'>{data && data.proposed_5yr || '-'}</td>
                <td className='col-1'>{data && data.proposed_st_action || '-'}</td>
                <td className='col-1'>{data && data.proposed_rating_action || '-'}</td>
                <td className='col-1'>{data && data.proposed_5yr_action || '-'}</td>
                <td className='col-1'>{data && data.proposed_cf || '-'}</td>
                <td className='col-1'></td>
            </tr>
            <tr>
                <td className='col-1'>Final</td>
                <td className='col-1'>{data && data.final_s_term || '-'}</td>
                <td className='col-1'>{data && data.final_l_term || '-'}</td>
                <td className='col-1'>{data && data.final_5yr || '-'}</td>
                <td className='col-1'>{data && data.final_st_action || '-'}</td>
                <td className='col-1'>{data && data.final_rating_action || '-'}</td>
                <td className='col-1'>{data && data.final_5yr_action || '-'}</td>
                <td className='col-1'>{data && data.final_cf || '-'}</td>
                <td className='col-1'>{Rating_data && Rating_data.rc_decision || '-'}</td>
            </tr>
            </tbody>}
        </table>
        </div>
    </div> :
    (
      <div className="mt-5 mx-auto" style={{ width: "fit-content" }}>
      <div class="wrapper" id="loader2">
        <svg
          class="hourglass"
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 120 206"
          preserveAspectRatio="none"
        >
          <path
            class="middle"
            d="M120 0H0v206h120V0zM77.1 133.2C87.5 140.9 92 145 92 152.6V178H28v-25.4c0-7.6 4.5-11.7 14.9-19.4 6-4.5 13-9.6 17.1-17 4.1 7.4 11.1 12.6 17.1 17zM60 89.7c-4.1-7.3-11.1-12.5-17.1-17C32.5 65.1 28 61 28 53.4V28h64v25.4c0 7.6-4.5 11.7-14.9 19.4-6 4.4-13 9.6-17.1 16.9z"
          />
          <path
            class="outer"
            d="M93.7 95.3c10.5-7.7 26.3-19.4 26.3-41.9V0H0v53.4c0 22.5 15.8 34.2 26.3 41.9 3 2.2 7.9 5.8 9 7.7-1.1 1.9-6 5.5-9 7.7C15.8 118.4 0 130.1 0 152.6V206h120v-53.4c0-22.5-15.8-34.2-26.3-41.9-3-2.2-7.9-5.8-9-7.7 1.1-2 6-5.5 9-7.7zM70.6 103c0 18 35.4 21.8 35.4 49.6V192H14v-39.4c0-27.9 35.4-31.6 35.4-49.6S14 81.2 14 53.4V14h92v39.4C106 81.2 70.6 85 70.6 103z"
          />
        </svg>
      </div>
    </div>
    )}
    <br/> <br/>
    </div>
  )
}
